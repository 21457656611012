.Footer-cont{
position: relative;

}
.footer{
display: flex;
flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}
.s-links{
    display: flex;
    gap: 4rem;
    cursor: pointer;
}

.s-links>img{
    width: 2rem;

}

@media (max-width:768px){
    .footer{
        display: flex;
        flex-direction: column;
            gap: 4rem;
            justify-content: start;
            align-items: flex-start;
   
        }

        .logo-f>img{
            width: 10rem;
        }
}