.testimonilas{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-t{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 2rem;
    text-transform: uppercase;
    color: #fff;
}

.left-t>:nth-child(1){
  color: var(--orange);
  font-weight: bold;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3){
   font-size: 3rem;
   font-weight: bold;
  }

  .left-t>:nth-child(4){
 text-transform: none;
 text-align: justify;
  }

  .right-t{
    flex: 1 ;
 
    position: relative;
  }
  .right-t>img{
    position: absolute;
    object-fit: cover;
    width: 17rem;
    height: 20rem;
    right: 8rem;
    top: 2rem;
  }

  .right-t>:nth-child(1){
    position: absolute;
    border: 2px solid orange;
    background: transparent;
    width: 17rem;
    height: 20rem;
    right: 9rem;
    top: 0.9rem;
  }
  .right-t>:nth-child(2){
    position: absolute;
    border: 0px solid orange;
    background: var(--planCard);
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
  }

  .arrows{
    display: flex;
    gap: 1rem;
   
    position: absolute;
    bottom: 1rem;
    left: 3rem;
    cursor: pointer;
  }

  .arrows>img{
    width: 1.5rem;
  }

  
@media (max-width:768px){
  .testimonilas{
    flex-direction: column;
  }
  .left-t>:nth-child(2),
  .left-t>:nth-child(3){
    font-size: x-large;
  }

  .right-t{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .right-t>div{
    display: none;
    position: relative;
  }
  .right-t>img{
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
  }
  .right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
  }
}