.join{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
}

.left-j{
    color: #fff;
    font-weight: bold;
    font-size: 3rem;
    position: relative;
}


.left-j>hr{
    border: 2px solid var(--orange);
    position: absolute;
    width: 10.5rem;
    border-radius: 20%;
    margin-top: -20px;
}

.eamil-Cont{
    display: flex;
    gap: 1rem;
    background: gray;
    padding: 1rem;
}

.btn-j{
    background: var(--orange);
    color: #fff;
    cursor: pointer;
    border: none;
}

.eamil-Cont>input{
    padding: 0.5rem;
    outline: none;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1rem;
}



@media (max-width:768px){
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .eamil-Cont{
        gap: 0;
    }
    .eamil-Cont>input{
        padding: 0;
        font-size: small;
    }
    .btn-j{
font-size: small;

    }
   
}