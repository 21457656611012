.plans{
    display: flex;
    padding: 0 2rem;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.plan{
    padding: 2rem;
    background: var(--caloryCard);
    display: flex;
    flex-direction: column;
    gap: 2rem;
   justify-content: center;
   cursor: pointer;
}
.plan:nth-child(2){
    transform: scale(1.1);
    background: var(--planCard);
}
.plans>:nth-child(2)>svg{
   fill: #fff;
}
.plan>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}

.plan>:nth-child(2){
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.plan>:nth-child(3){
    font-size: 2.5rem;
    font-weight: bold;
    color: #fff;
  }
  .plan>:nth-child(5){
 color: var(--orange);
 cursor: pointer;
  }


  .features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
  }

  .feature{
    display: flex;
  

    gap: 1rem;
  }
  .feature>img{
    width: 1rem;
    height: 1rem;
  }


  
@media (max-width:768px){
  .plans{
    flex-direction: column;
  }
  .plan:nth-child(2){
    transform: none;
  }
}