.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img{
    width: 10rem;
    height: 3rem;

    cursor: pointer;

}
.header-menu{
    display: flex;
    list-style: none;
    gap: 2rem;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}
.header-menu>li:hover{
    color: var(--orange);
}

@media (max-width:768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 1rem;
        background: var(--appColor);
        z-index: 99;
        }
        .header-menu{
            flex-direction: column;
            padding: 2rem;
            top: 2rem;
        }
}

